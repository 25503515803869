import React from "react";
import moment from "moment";
import { GiftOutlined, UserOutlined, TeamOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Checkbox, Input, notification, Skeleton } from "antd";
import { getConfig } from "../../../actions/appConfigActions";
import { getEvent } from "../../../actions/eventsActions";
import { getVideo } from "../../../actions/videosActions";
import {
  buyTicket as buyEventTicket,
  clearPaymentError as clearEventPaymentError,
} from "../../../actions/paymentsActions";
import {
  buyTicket as buyVideoTicket,
  clearPaymentError as clearVideoPaymentError,
} from "../../../actions/videoPaymentsActions";
import {
  buyGift,
  clearGiftPaymentError,
  getGiftSettings,
} from "../../../actions/giftsActions";
import {
  buyGroupTicket,
  clearGroupTicketPaymentError,
  getGroupTicketSettings,
} from "../../../actions/groupTicketsActions";
import "./PaymentPage.scss";
import Wrapper from "../../wrappers/Wrapper";
import InvoiceForm from "./InvoiceForm";
import {
  allowCompanyInvoices,
  allowInvoices,
  googleTagManager,
  paymentCheckbox,
  paymentCheckboxEn,
  paymentVatInfo,
  paymentVatInfoEn,
} from "../../../theme-variables";
import tc from "../../../helpers/translateContent";
import {
  checkEventStatusByDate,
  getDatetimeRange,
} from "../../../helpers/productUtilities";
import { facebookPixel } from "../../../theme-variables";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

class PaymentPage extends React.Component {
  state = {
    ticketType: "standard",
    needInvoice: false,
    additionalCb: false,
    additionalCbError: false,
    productId: null,
    productType: null,
    invoiceData: {
      street: this.props.user.street,
      postal_code: this.props.user.postal_code,
      country_code: this.props.user.country_code,
      city: this.props.user.city,
      state: this.props.user.state,
      nip: this.props.user.nip,
      company: this.props.user.company,
      firstname: this.props.user.firstname,
      lastname: this.props.user.lastname,
    },
    invoiceType: "person",
    ticketsQuantity: this.props.groupTicketConfig
      ? this.props.groupTicketConfig.min_units
      : null,
    ticketsQuantityError: "",
    ticketsQuantityChanged: false,
  };
  componentDidMount() {
    const productId = this.props.match.params.productId;
    const productType = this.props.match.params.productType;
    if (productType === "wideo") this.props.getVideo(productId);
    else this.props.getEvent(productId);

    this.props.getConfig();
    this.props.getGiftSettings();
    this.props.getGroupTicketSettings();
    this.setState({
      productId,
      productType: productType === "wydarzenie" ? "event" : "video",
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.payment.loading &&
      !this.props.payment.success &&
      this.props.payment.error
    ) {
      notification.error({
        message: this.props.t("const.error_occured"),
        description: this.props.payment.error,
      });
      this.props.clearEventPaymentError();
      this.props.clearVideoPaymentError();
    }
    if (
      !this.props.giftPayment.loading &&
      !this.props.giftPayment.success &&
      this.props.giftPayment.error
    ) {
      notification.error({
        message: this.props.t("const.error_occured"),
        description: this.props.giftPayment.error,
      });
      this.props.clearGiftPaymentError();
    }
    if (
      !this.props.groupTicketPayment.loading &&
      !this.props.groupTicketPayment.success &&
      this.props.groupTicketPayment.error
    ) {
      notification.error({
        message: this.props.t("const.error_occured"),
        description: this.props.groupTicketPayment.error,
      });
      this.props.clearGroupTicketPaymentError();
    }
    return null;
  };
  componentWillUnmount = () => {
    this.props.clearEventPaymentError();
    this.props.clearVideoPaymentError();
    this.props.clearGiftPaymentError();
    this.props.clearGroupTicketPaymentError();
  };
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  renderDate = (date) => moment(date).format("DD.MM.YYYY");
  renderEventDescription = () => {
    const { t, product } = this.props;
    if (!product) return null;
    const {
      title,
      translations,
      date_from,
      date_to,
      time_from,
      time_to,
      full_day,
      hide_time_to,
    } = product;
    const datetimeRange = getDatetimeRange(
      date_from,
      date_to,
      time_from,
      time_to,
      full_day
    );
    const time =
      datetimeRange.from.format("HH:mm") === "00:00" &&
      datetimeRange.to.format("HH:mm") === "23:59"
        ? t("videodesc.full_day")
        : hide_time_to
        ? datetimeRange.from.format("H:mm")
        : full_day || (time_from == "00:00:00" && time_to == "23:59:59")
        ? t("videodesc.from") +
          " " +
          datetimeRange.from.format("H:mm D.MM") +
          ` do ${datetimeRange.to.format("H:mm D.MM")}`
        : this.getTimeRange(
            datetimeRange.from.format("H:mm"),
            datetimeRange.to.format("H:mm")
          );

    const date = this.getDateRange(
      datetimeRange.from.format("DD.MM.YYYY"),
      datetimeRange.to.format("DD.MM.YYYY")
    );

    return (
      <>
        <h2>{tc(title, "title", translations)}</h2>
        <p className="product-description">
          {t("paymentpage.event_on")}
          <br />
          {date}
          <br />({time})
        </p>
      </>
    );
  };
  renderVideoDescription = () => {
    const { product, ticketsExpirationTime, t } = this.props;
    const { ticketType } = this.state;
    if (!product) return null;
    const {
      title,
      translations,
      end_date,
      available_from,
      tickets_expiration_time,
    } = product;

    const expTimeOverriden = tickets_expiration_time
      ? tickets_expiration_time
      : tickets_expiration_time === 0
      ? null
      : ticketsExpirationTime;

    if (ticketType === "standard")
      return (
        <>
          <h2>{tc(title, "title", translations)}</h2>
          <p className="product-description">
            {end_date ? (
              <>
                {t("paymentpage.use_ticket_to")} {this.renderDate(end_date)}{" "}
                {t("paymentpage.to_hour")} {moment(end_date).format("H:mm")}
                <br />
              </>
            ) : null}
            {expTimeOverriden ? (
              end_date &&
              moment(end_date).isBefore(
                moment().add(expTimeOverriden, "hours")
              ) ? (
                <>
                  <span className="warning">
                    {t("paymentpage.ticket_to")} {this.renderDate(end_date)}{" "}
                    {t("paymentpage.to_hour")} {moment(end_date).format("H:mm")}
                  </span>
                </>
              ) : (
                <>
                  {t("paymentpage.ticket_to2")} {expTimeOverriden}{" "}
                  {t("paymentpage_hour_from")}{" "}
                  {moment(available_from).isAfter(moment())
                    ? t("paymentpage.premiere")
                    : t("paymentpage.payment")}
                </>
              )
            ) : null}
          </p>
        </>
      );
    else
      return (
        <>
          <h2>{tc(title, "title", translations)}</h2>
          <p className="product-description">
            {expTimeOverriden ? (
              <>
                {t("paymentpage.ticket_valid")} {expTimeOverriden}{" "}
                {t("paymentpage.hours_from_activation")}
                {end_date
                  ? `${t("paymentpage.but_not")} ${this.renderDate(
                      end_date
                    )} ${t("paymentpage.to_hour")} ${moment(end_date).format(
                      "H:mm"
                    )}.`
                  : "."}
              </>
            ) : null}
          </p>
        </>
      );
  };
  renderPrice = () => {
    const { productType, ticketType, ticketsQuantity } = this.state;
    const { groupTicketConfig, t } = this.props;
    let product = this.props.product;
    if (productType === "video") product.price = product.video_price;
    else product.price = product.event_price;
    let amountNotDiscounted = 0;
    if (groupTicketConfig && ticketType === "group") {
      let amount =
        product.price * (ticketsQuantity || groupTicketConfig.min_units);
      amountNotDiscounted = amount;
      if (groupTicketConfig && groupTicketConfig.one_free)
        amount -= product.price;
      if (
        groupTicketConfig &&
        groupTicketConfig.discount &&
        groupTicketConfig.discount > 0
      )
        amount = ((100 - groupTicketConfig.discount) / 100) * amount;
      return (
        <div className="final-price">
          {amount > 0 ? (
            (groupTicketConfig.discount && amountNotDiscounted < amount) ||
            groupTicketConfig.one_free ? (
              <>
                <del>{amountNotDiscounted} zł</del>
                <span>
                  {groupTicketConfig.discount && groupTicketConfig.discount > 0
                    ? ` (-${groupTicketConfig.discount}%) `
                    : ""}
                </span>
                <br />
                <div className="final-price">
                  {amount} zł
                  <span>
                    {this.props.i18n.language === "pl"
                      ? paymentVatInfo
                      : paymentVatInfoEn}
                  </span>
                </div>
              </>
            ) : (
              <>
                {amount} zł
                <span>
                  {this.props.i18n.language === "pl"
                    ? paymentVatInfo
                    : paymentVatInfoEn}
                </span>
              </>
            )
          ) : (
            t("paymentpage.wrong_number")
          )}
        </div>
      );
    } else if (product.usedCode && !product.access) {
      return (
        <>
          <del>{product.price} zł</del>
          <br />
          <div className="final-price">
            {product.newPrice} zł
            <span>
              {this.props.i18n.language === "pl"
                ? paymentVatInfo
                : paymentVatInfoEn}
            </span>
          </div>
          <small>
            {t("paymentpage.used_code")}: <i>{product.usedCode}</i>.<br />
            {t("paymentpage.code_not_for")}
          </small>
        </>
      );
    } else
      return (
        <div className="final-price">
          {product.price} zł
          <span>
            {this.props.i18n.language === "pl"
              ? paymentVatInfo
              : paymentVatInfoEn}
          </span>
        </div>
      );
  };
  getReasonWhyNotAvailable = () => {
    const { t } = this.props;
    const { birthday } = this.props.user;
    const ageRestriction = this.props.product.age_restriction;
    const countryBlock = this.props.product.countryBlock;
    const { productType, ticketType } = this.state;
    const { product, groupTicketConfig } = this.props;
    let reason = null;
    if (
      birthday &&
      ageRestriction &&
      moment().diff(birthday, "years") < ageRestriction
    )
      reason = t("videodesc.age_restriction");
    if (countryBlock) reason = t("videodesc.country_blocked");

    switch (productType) {
      case "video":
        const { end_date } = product;
        if (end_date && moment().isAfter(moment(end_date)))
          reason = t("paymentpage.video_not_available");
        break;
      case "event":
        const {
          date_from,
          time_from,
          date_to,
          time_to,
          full_day,
          ticketsAvailable,
          enable_tickets,
        } = product;
        const eventStatus = checkEventStatusByDate(
          date_from,
          date_to,
          full_day,
          time_from,
          time_to
        );
        if (!enable_tickets) reason = t("paymentpage.payments_not_available");
        if (!ticketsAvailable) reason = t("videodesc.no_tickets");
        if (eventStatus === -1) reason = t("videodesc.event_off");
        break;
      default:
        break;
    }
    if (ticketType === "group" && productType === "event") {
      const { ticketsLeft } = product;
      if (ticketsLeft && ticketsLeft < groupTicketConfig.min_units)
        reason = t("videodesc.no_tickets");
    }
    if (!reason) return null;
    return (
      <Button className="payment-button red" disabled>
        {reason}
      </Button>
    );
  };
  renderSwitcher = () => {
    const { t, groupTicketConfig, product, ticketsExpirationTime } = this.props;
    const { productType, ticketType } = this.state;

    const expTimeOverriden = product.tickets_expiration_time
      ? product.tickets_expiration_time
      : product.tickets_expiration_time === 0
      ? null
      : ticketsExpirationTime;

    if (
      !groupTicketConfig ||
      (productType === "event" && !groupTicketConfig.event_available) ||
      (productType === "video" && !groupTicketConfig.video_available) ||
      !groupTicketConfig.min_units ||
      !groupTicketConfig.max_units
    )
      return null;
    if (
      productType === "event" &&
      product.ticketsLeft &&
      product.ticketsLeft < groupTicketConfig.min_units
    )
      return null;
    const productPrice =
      productType === "event" ? product.event_price : product.video_price;
    if (productPrice == 0) return null;
    if (ticketType === "standard")
      return (
        <>
          <Button
            className={`payment-button gift-button`}
            icon={<TeamOutlined />}
            onClick={() => {
              document
                .querySelector(".payment-block")
                .scrollIntoView({ block: "start", behavior: "smooth" });
              this.setState({ ticketType: "group" });
            }}
          >
            {t("videodesc.buy_group_ticket")}
          </Button>
          <small>
            {groupTicketConfig.one_free
              ? t("paymentpage.one_free")
              : t("paymentpage.not_one_free")}
            {productType === "video" && expTimeOverriden ? (
              <>
                {" "}
                {t("paymentpage.after_activation")} {expTimeOverriden}{" "}
                {t("paymentpage.to_end_date")}
              </>
            ) : null}
          </small>
        </>
      );
    else
      return (
        <Button
          className={`payment-button gift-button`}
          icon={<UserOutlined />}
          onClick={() => {
            document
              .querySelector(".payment-block")
              .scrollIntoView({ block: "start", behavior: "smooth" });
            this.setState({ ticketType: "standard" });
          }}
        >
          {t("paymentpage.single_ticket")}
        </Button>
      );
  };
  renderGiftButton = () => {
    const {
      giftPayment,
      t,
      ticketsExpirationTime,
      product,
      giftConfig,
    } = this.props;
    const { productType } = this.state;
    const productPrice =
      productType === "event" ? product.event_price : product.video_price;
    if (!giftConfig || !giftConfig.gift_available) return null;
    if (productPrice == 0) return null;

    const expTimeOverriden = product.tickets_expiration_time
      ? product.tickets_expiration_time
      : product.tickets_expiration_time === 0
      ? null
      : ticketsExpirationTime;

    return (
      <>
        <Button
          className={`payment-button gift-button`}
          loading={giftPayment.loading}
          onClick={() => this.handlePayment("gift")}
          icon={<GiftOutlined />}
        >
          {t("paymentpage.buy_as_a_gift")}
        </Button>
        <small>
          {t("paymentpage.buy_as_a_gift_info")}
          {productType === "video" && expTimeOverriden ? (
            <>
              {" "}
              {t("paymentpage.after_activation")} {expTimeOverriden}{" "}
              {t("paymentpage.to_end_date")}
            </>
          ) : null}
        </small>
      </>
    );
  };
  renderPaymentButton = () => {
    const {
      access,
      paymentStatus,
      newPrice,
      expiration_date,
      end_date,
      video_price,
      event_price,
      available_tickets,
    } = this.props.product;
    const { payment, t, groupTicketPayment } = this.props;
    const { productType, ticketType, ticketsQuantityError } = this.state;
    const productPrice = productType === "event" ? event_price : video_price;
    if (ticketType === "group") {
      return (
        <Button
          type="primary"
          className={`payment-button white`}
          loading={groupTicketPayment.loading}
          disabled={ticketsQuantityError ? true : false}
          onClick={() => this.handlePayment("group")}
        >
          {t("paymentpage.go_to_payment")}
        </Button>
      );
    }
    if (
      !access &&
      ["CANCELED", "NOT_STARTED", "UNDEFINED", "REFUNDED", null].some((v) =>
        paymentStatus.includes(v)
      )
    ) {
      return (
        <Button
          type="primary"
          className={`payment-button white`}
          loading={payment.loading}
          onClick={() => this.handlePayment(productType)}
        >
          {Number.parseFloat(productPrice) === 0 &&
          productType === "event" &&
          available_tickets > 0
            ? t("videodesc.get_access")
            : Number.parseFloat(newPrice) === 0
            ? t("videodesc.use_code")
            : t("paymentpage.go_to_payment")}
        </Button>
      );
    } else if (
      !access &&
      ["PENDING", "WAITING_FOR_CONFIRMATION", null].some((v) =>
        paymentStatus.includes(v)
      )
    )
      return (
        <Button
          type="primary"
          className={`payment-button orange`}
          loading={payment.loading}
          disabled
        >
          {t("paymentpage.payment_pending")}
        </Button>
      );
    else if (access) {
      const expirationDate =
        expiration_date && expiration_date !== "0000-00-00"
          ? `${t("videodesc.have_access_to")}: ${
              end_date && moment(end_date).isBefore(moment(expiration_date))
                ? moment(end_date).format("H:mm, DD.MM.YYYY")
                : moment(expiration_date).format("H:mm, DD.MM.YYYY")
            }`
          : null;
      return (
        <>
          <Button
            type="primary"
            className={`payment-button green`}
            loading={payment.loading}
            disabled
          >
            {t("videodesc.access")}
          </Button>
          {expirationDate ? (
            <p className="payment-button-desc">{expirationDate}</p>
          ) : null}
        </>
      );
    }
    return;
  };
  renderQuantityForm = () => {
    const { ticketType } = this.state;
    if (ticketType !== "group") return null;
    const { groupTicketConfig, product, t } = this.props;
    if (!groupTicketConfig) return null;
    const { max_units, min_units, one_free } = groupTicketConfig;
    const { ticketsLeft } = product;
    if (ticketsLeft && ticketsLeft < min_units) return null;
    const {
      ticketsQuantityChanged,
      ticketsQuantityError,
      ticketsQuantity,
    } = this.state;
    return (
      <>
        <label className="quantity-form">
          <span className="quantity-form-error">
            {ticketsQuantityChanged ? ticketsQuantityError : <>&#8232;</>}
          </span>
          {t("paymentpage.enter_ticket_number")}
          <Input
            type="number"
            value={ticketsQuantity || min_units}
            step={1}
            min={min_units}
            max={
              ticketsLeft && ticketsLeft < max_units ? ticketsLeft : max_units
            }
            placeholder={min_units}
            onChange={(e) => {
              const val = e.target.value;
              this.setState({
                ticketsQuantity: val,
                ticketsQuantityError:
                  ticketsLeft && ticketsLeft > 0 && val > ticketsLeft
                    ? `${t("paymentpage.tickets_available")} ${ticketsLeft}`
                    : val > max_units || val < min_units
                    ? `${t("paymentpage.range_from")} ${min_units} ${t(
                        "paymentpage.range_to"
                      )} ${max_units}`
                    : "",
                ticketsQuantityChanged: true,
              });
            }}
          />
          <small>
            {t("paymentpage.ticket_as_a_code")}
            {one_free ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: ` ${t("paymentpage.one_for_free")}`,
                }}
              />
            ) : null}
          </small>
        </label>
      </>
    );
  };
  onInvoiceCbChange = (checked) => {
    this.setState({ needInvoice: checked });
  };
  onAdditionalCbChange = (checked) => {
    this.setState({ additionalCb: checked, additionalCbError: false });
  };
  changeInvoiceData = (newState) => {
    this.setState({ invoiceData: { ...this.state.invoiceData, ...newState } });
  };
  changeInvoiceType = (val) => {
    this.setState({ invoiceType: val });
  };
  handlePayment = (type) => {
    const {
      postal_code,
      city,
      state,
      country_code,
      street,
      company,
      firstname,
      lastname,
      nip,
    } = this.state.invoiceData;
    const { invoiceType, needInvoice, additionalCb } = this.state;
    const { t } = this.props;

    if (!additionalCb) {
      this.setState({ additionalCbError: true });
      return;
    }

    let finalInvoiceData = {};
    if (needInvoice) {
      let newState = {};
      if (allowCompanyInvoices && invoiceType === "company") {
        if (!company) newState.company_error = t("form.validate.required");
        else if (company.length > 100)
          newState.company_error = t("form.validate.wrong");
        if (!nip) newState.nip_error = t("form.validate.required");
        else if (nip.length > 100 || !/^[0-9]*$/.test(nip))
          newState.nip_error = t("form.validate.wrong");
      } else {
        if (!firstname) newState.firstname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(firstname))
          newState.firstname_error = t("form.validate.wrong");
        if (!lastname) newState.lastname_error = t("form.validate.required");
        else if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(lastname))
          newState.lastname_error = t("form.validate.wrong");
      }
      if (!postal_code)
        newState.postal_code_error = t("form.validate.required");
      if (!city) newState.city_error = t("form.validate.required");
      if (!state) newState.state_error = t("form.validate.required");
      if (!country_code)
        newState.country_code_error = t("form.validate.required");
      if (!street) newState.street_error = t("form.validate.required");
      if (
        postal_code &&
        (postal_code.length > 10 || /<[a-z/][\s\S]*>/.test(postal_code))
      )
        newState.postal_code_error = t("form.validate.wrong_input_max10");
      if (city && (city.length > 30 || /<[a-z/][\s\S]*>/.test(city)))
        newState.city_error = t("form.validate.wrong_input_max30");
      if (state && (state.length > 30 || /<[a-z/][\s\S]*>/.test(state)))
        newState.state_error = t("form.validate.wrong_input_max30");
      if (
        country_code &&
        (country_code.length > 2 || /<[a-z/][\s\S]*>/.test(country_code))
      )
        newState.country_code_error = t("form.validate.wrong_input_max2");

      if (Object.keys(newState).length > 0) {
        this.setState({
          invoiceData: { ...this.state.invoiceData, ...newState },
        });
        return;
      }

      finalInvoiceData = {
        postal_code,
        city,
        state,
        country_code,
        street,
        company,
        firstname,
        lastname,
        nip,
        invoiceType: allowCompanyInvoices ? invoiceType : "person",
      };
    }
    const { productType, productId, ticketsQuantity } = this.state;

    const { title, date_from, date_to } = this.props.product;
    const dateFrom = moment(date_from).format("DD.MM.YYYY");
    const dateTo = moment(date_to).format("DD.MM.YYYY");
    const productPrice = this.props.product
      ? this.props.productType === "event"
        ? this.props.product.event_price
        : this.props.product.video_price
      : null;
    if (facebookPixel) {
      ReactPixel.track("AddToCart", {
        content_ids: [
          `${
            type !== "event" && type !== "video"
              ? `${type}-${productType}-`
              : `${productType}-`
          }${productId}`,
        ],
        content_name:
          type === "video" || productType === "video"
            ? title
            : `${title}${
                dateFrom !== dateTo
                  ? ` (${dateFrom}-${dateTo})`
                  : ` (${dateFrom})`
              }`,
        content_type: type,
        contents: [
          {
            id: `${productType}-${productId}`,
            quantity: type === "group" ? ticketsQuantity : 1,
          },
        ],
        currency: "PLN",
        value:
          parseFloat(productPrice) * (type === "group" ? ticketsQuantity : 1),
      });
    }
    if (googleTagManager) {
      TagManager.dataLayer({ ecommerce: null });
      TagManager.dataLayer({
        event: "addToCart",
        ecommerce: {
          currencyCode: "PLN",
          add: {
            products: [
              {
                name:
                  type === "video" || productType === "video"
                    ? title
                    : `${title}${
                        dateFrom !== dateTo
                          ? ` (${dateFrom}-${dateTo})`
                          : ` (${dateFrom})`
                      }`,
                id: `${productType}-${productId}`,
                price:
                  parseFloat(productPrice) *
                  (type === "group" ? ticketsQuantity : 1),
                category: type,
                quantity: type === "group" ? ticketsQuantity : 1,
              },
            ],
          },
        },
      });
    }
    switch (type) {
      case "video":
        this.props.buyVideoTicket(productId, finalInvoiceData);
        break;
      case "event":
        this.props.buyEventTicket(productId, finalInvoiceData);
        break;
      case "gift":
        this.props.buyGift(productId, productType, finalInvoiceData);
        break;
      case "group":
        this.props.buyGroupTicket(
          productId,
          productType,
          ticketsQuantity || this.props.groupTicketConfig.min_units,
          finalInvoiceData
        );
        break;
      default:
        break;
    }
  };
  render() {
    const { t, product } = this.props;
    const {
      ticketType,
      productType,
      productId,
      needInvoice,
      additionalCb,
      additionalCbError,
      invoiceData,
      invoiceType,
    } = this.state;
    const productPrice = product
      ? productType === "event"
        ? product.event_price
        : product.video_price
      : null;
    if (productType && productId && product && !this.getReasonWhyNotAvailable())
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            <p>
              {this.props.user.firstname}, {t("paymentpage.great_choice")}
              {ticketType === "group"
                ? ` – ${t("paymentpage.group_ticket_for")}`
                : ""}
              :
            </p>
            {productType === "event"
              ? this.renderEventDescription()
              : this.renderVideoDescription()}
            {this.renderQuantityForm()}
            <div className="payment-checkboxes">
              {productPrice > 0 && allowInvoices ? (
                <Checkbox
                  checked={needInvoice}
                  onChange={(e) => this.onInvoiceCbChange(e.target.checked)}
                  style={{ marginBottom: 20 }}
                >
                  {t("videodesc.need_invoice")}
                </Checkbox>
              ) : null}
              {needInvoice && allowInvoices ? (
                <InvoiceForm
                  changeInvoiceData={this.changeInvoiceData}
                  changeInvoiceType={this.changeInvoiceType}
                  invoiceData={invoiceData}
                  invoiceType={invoiceType}
                />
              ) : null}
              {paymentCheckbox ? (
                <Checkbox
                  checked={additionalCb}
                  onChange={(e) => this.onAdditionalCbChange(e.target.checked)}
                >
                  <span
                    className={additionalCbError ? `cb-error` : ""}
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.i18n.language === "pl"
                          ? paymentCheckbox
                          : paymentCheckboxEn || paymentCheckbox,
                    }}
                  />
                </Checkbox>
              ) : null}
            </div>
            <div className="payment-price">
              {this.renderPrice()}
              {this.renderPaymentButton()}
            </div>
            <div className="additional-payments">
              {this.renderGiftButton()}
              {this.renderSwitcher()}
            </div>
          </div>
        </Wrapper>
      );
    else if (
      productType &&
      productId &&
      product &&
      this.getReasonWhyNotAvailable()
    )
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <div className="payment-block">
            <p>
              {this.props.user.firstname}, {t("paymentpage.great_choice")}
              {ticketType === "group"
                ? ` – ${t("paymentpage.group_ticket_for")}`
                : ""}
              :
            </p>
            {productType === "event"
              ? this.renderEventDescription()
              : this.renderVideoDescription()}
            {this.getReasonWhyNotAvailable()}
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <h1>{t("videodesc.buy")}</h1>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment:
      ownProps.match.params.productType === "wydarzenie"
        ? state.payments
        : state.videoPayments,
    giftPayment: state.gifts || { loading: false },
    groupTicketPayment: state.groupTickets || { loading: false },
    ticketsExpirationTime: state.appConfig.config.tickets_expiration_time,
    user: state.auth.user,
    product:
      ownProps.match.params.productType === "wydarzenie"
        ? state.events.events.find(
            (e) => e.id === Number.parseInt(ownProps.match.params.productId)
          )
        : state.videos.videos.find(
            (v) => v.id === Number.parseInt(ownProps.match.params.productId)
          ),
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    giftConfig: state.gifts.config,
    groupTicketConfig: state.groupTickets.config,
  };
};

export default connect(mapStateToProps, {
  getEvent,
  getVideo,
  buyEventTicket,
  buyVideoTicket,
  buyGift,
  buyGroupTicket,
  clearEventPaymentError,
  clearVideoPaymentError,
  clearGiftPaymentError,
  clearGroupTicketPaymentError,
  getConfig,
  getGroupTicketSettings,
  getGiftSettings,
})(withTranslation()(PaymentPage));
