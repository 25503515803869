import React from "react";
import moment from "moment";
import { Button } from "antd";
import { Link } from "react-router-dom";
import "./VideoCard.scss";
import { withTranslation } from "react-i18next";
import tc from "../../../helpers/translateContent";
import TagManager from "react-gtm-module";
import { googleTagManager, imageServerUrl } from "../../../theme-variables";

class VideoCard extends React.Component {
  pushDataLayer = () => {
    const { video } = this.props;
    if (googleTagManager) {
      TagManager.dataLayer({ ecommerce: null });
      TagManager.dataLayer({
        event: "productClick",
        ecommerce: {
          click: {
            products: [
              {
                name: video.title,
                id: video.id,
                price: video.video_price,
                category: "video",
              },
            ],
          },
        },
        eventCallback: function () {
          document.location = `/wideo/${video.slug || video.id}`;
        },
      });
    }
  };
  renderPaymentButton() {
    const { video, userRole, t } = this.props;

    const paymentProcessing =
      video.paymentStatus === "PENDING" ||
      video.paymentStatus === "WAITING_FOR_CONFIRMATION";

    const hasAccess =
      Number.parseFloat(video.video_price) === 0 ||
      video.paymentStatus === "COMPLETED" ||
      userRole === "admin" ||
      userRole === "premium";

    if (paymentProcessing)
      return (
        <Button className="orange" disabled>
          {t("button.processing")}
        </Button>
      );

    if (!hasAccess)
      return (
        <Link
          to={`/wideo/${video.slug || video.id}`}
          onClick={this.pushDataLayer}
        >
          <Button className="black">{t("button.buy")}</Button>
        </Link>
      );

    if (moment(video.available_from).isAfter(moment()))
      return (
        <Link
          to={`/wideo/${video.slug || video.id}`}
          onClick={this.pushDataLayer}
        >
          <Button className="blue">{t("button.soon")}</Button>
        </Link>
      );

    return (
      <Link
        to={`/wideo/${video.slug || video.id}?ogladaj=true`}
        onClick={this.pushDataLayer}
      >
        <Button className="green">{t("button.watch")}</Button>
      </Link>
    );
  }
  render() {
    const { video, t } = this.props;
    let classes = "mix ";
    if (Array.isArray(video.categories))
      video.categories.forEach((c) => {
        classes += `category-${c} `;
      });
    return (
      <div
        className={`video-card ${video.visible === 1 ? "" : "not-published"} ${
          video.dashboard_hidden !== 1 ? "" : "not-published"
        }
        ${
          video.end_date && moment(video.end_date).isBefore(moment())
            ? "not-published"
            : ""
        }
        ${classes} ${
          moment(video.available_from).isAfter(moment()) ? "not-available" : ""
        }`}
      >
        <div
          className="video-thumb"
          style={{
            backgroundImage: `url(${imageServerUrl}${
              video.thumb_link
                ? video.thumb_link
                : video.image_link
                ? video.image_link
                : null
            })`,
          }}
        >
          {moment(video.available_from).isAfter(moment()) ? (
            <div className="video-status">
              {t("videodesc.available")}
              <br />
              {moment(video.available_from).format("DD.MM")}{" "}
              {t("videodesc.from")}{" "}
              {moment(video.available_from).format("H:mm")}
            </div>
          ) : null}
          <Link to={`/wideo/${video.id}`}>
            {/* <img src={video.thumb_link} alt={video.title} /> */}
          </Link>
        </div>
        <div className="video-desc">
          <div className="video-title">
            <Link
              to={`/wideo/${video.slug || video.id}`}
              onClick={this.pushDataLayer}
            >
              {tc(video.title, "title", video.translations)}
              {video.subtitle ? (
                <span className="video-subtitle">
                  {tc(video.subtitle, "subtitle", video.translations)}
                </span>
              ) : null}
            </Link>
          </div>
          <div className="video-payment">
            <div className="video-payment-status">
              {this.renderPaymentButton()}
            </div>
            <div className="video-price">
              {Number.parseFloat(video.video_price) === 0.0 ? (
                t("videodesc.free_access")
              ) : video.newPrice !== undefined ? (
                <>
                  {parseFloat(video.newPrice).toFixed(2)}{" "}
                  <del>{video.video_price}</del> zł
                </>
              ) : (
                `${video.video_price} zł`
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VideoCard);
