import React from "react";
import { Button, Statistic, Tag } from "antd";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { renderPreview } from "../../../helpers/renderPreview";
import "./Node.scss";
import { Link } from "react-router-dom";
import { imageServerUrl } from "../../../theme-variables";

class Node extends React.Component {
  state = { teaserOn: false, countdownEnd: false };
  renderTeaserButton = () => {
    const { teaserLink, t } = this.props;
    const { teaserOn } = this.state;
    if (!teaserLink) return null;
    return (
      <Button
        className="trailer-button"
        onClick={() => {
          if (!teaserOn)
            document
              .querySelector(".media")
              .scrollIntoView({ block: "start", behavior: "smooth" });
          this.setState({ teaserOn: !teaserOn });
        }}
      >
        {teaserOn ? t("videodesc.stop_trailer") : t("videodesc.watch_trailer")}
      </Button>
    );
  };
  renderWatchButton = () => {
    const {
      access,
      available,
      countryBlock,
      userBirthday,
      ageRestriction,
      turnOnTransmission,
      t,
    } = this.props;
    const { countdownEnd } = this.state;
    if (!access || (!available && !countdownEnd) || countryBlock) return null;
    if (userBirthday && moment().diff(userBirthday, "years") < ageRestriction)
      return null;
    return (
      <Button onClick={turnOnTransmission} className="watch-button">
        <Link to="?ogladaj=true" title={t("button.watch")}>
          {t("button.watch")}
        </Link>
      </Button>
    );
  };
  renderPaymentButton = (buttonProps) => {
    if (!buttonProps) return null;
    if (Object.keys(buttonProps).length === 0) return null;
    return (
      <>
        <Button
          className={`payment-button ${buttonProps.color}`}
          disabled={buttonProps.disabled}
          loading={buttonProps.loading}
          onClick={buttonProps.onClick}
        >
          {buttonProps.text}
        </Button>
        {buttonProps.additionalDesc ? (
          <p className="payment-button-desc">{buttonProps.additionalDesc}</p>
        ) : null}
      </>
    );
  };
  renderPrice = () => {
    const { t } = this.props;
    const { newPrice, price, code } = this.props.price;
    return newPrice !== undefined ? (
      <>
        {t("videodesc.price")}: {parseFloat(newPrice).toFixed(2)}{" "}
        <del>{price}</del> zł
        <small style={{ paddingLeft: 5 }}>
          ({t("videodesc.used_code")}: {code})
        </small>
      </>
    ) : (
      `${t("videodesc.price")}: ${price} zł`
    );
  };
  renderPaymentStatus = () => {
    const { t, paymentStatus } = this.props;
    if (!paymentStatus)
      return <p className="payment-status">{this.renderPrice()}</p>;
    return (
      <>
        <p className="payment-status">
          {paymentStatus.text && paymentStatus.tagColor ? (
            <>
              <div className="tag-container">
                <span className="tag-text">
                  {t("payments.payment_status")}:
                </span>
                <Tag color={paymentStatus.tagColor}>{paymentStatus.text}</Tag>
              </div>
              <br />
            </>
          ) : null}
          {paymentStatus.renderPrice ? this.renderPrice() : null}
        </p>
      </>
    );
  };
  renderVideoInfo = () => {
    const { duration, ageRestriction, t } = this.props;
    return (
      <>
        {duration ? (
          <p>
            {t("videodesc.duration")} – <b>{duration} min</b>
          </p>
        ) : null}
        {ageRestriction ? (
          <p>
            {t("videodesc.from_age")} – <b>+{ageRestriction}</b>
          </p>
        ) : null}
      </>
    );
  };
  renderTimeInCountdown = (countdownDate) => {
    const { t } = this.props;
    let diff = moment.duration(countdownDate.diff(moment()));
    if (diff.asHours() > 24) {
      return Math.floor(diff.asDays()) === 1
        ? t("videodesc.one_day")
        : `${Math.floor(diff.asDays())} ${t("videodesc.days")}`;
    }
    const randomTimeout =
      Math.floor(Math.random() * (Math.floor(8000) - Math.ceil(3000) + 1)) +
      Math.ceil(3000);
    return (
      <Statistic.Countdown
        value={countdownDate.add(randomTimeout, "milliseconds")}
        format={`H:mm:ss`}
        onFinish={() => {
          this.setState({ countdownEnd: true });
        }}
      />
    );
  };
  renderCountdown = () => {
    const {
      available,
      countdownDate,
      countdownText,
      t,
      availableText,
      forceCountdown,
      productType,
    } = this.props;
    const { countdownEnd } = this.state;
    if (!forceCountdown && available)
      return <div className="countdown">{availableText}</div>;
    if (countdownEnd)
      return <div className="countdown">{t("videodesc.countdown_end")}</div>;
    if (!countdownDate)
      return (
        <p>
          {productType === "video"
            ? t("videodesc.video_off")
            : t("videodesc.event_off")}
        </p>
      );

    return (
      <div className="countdown">
        {countdownText ? <span>{countdownText}: </span> : null}
        {this.renderTimeInCountdown(countdownDate)}
      </div>
    );
  };
  renderMedia = () => {
    const { teaserOn } = this.state;
    const { gallery, mainImage, teaserLink } = this.props;
    if (teaserLink && teaserOn)
      return (
        <div className="teaser-container">{renderPreview(teaserLink)}</div>
      );
    if (gallery && gallery.images.length > 0) {
      return (
        <div className="gallery-container">
          <ImageGallery
            showPlayButton={false}
            showBullets={false}
            showFullscreenButton={false}
            items={gallery.images.map((img) => {
              return {
                original: img.url,
                thumbnail: img.grid_thumb_url || img.url,
              };
            })}
          />
        </div>
      );
    }
    if (mainImage)
      return (
        <div className="image-container">
          <img src={imageServerUrl + mainImage} alt="Zdjęcie ze spektaklu" />
        </div>
      );
  };
  render() {
    const { title, date, time, description, gallery, additionalDesc } =
      this.props;
    const nodeClasses = `node ${
      gallery && gallery.images.length > 0
    } node-gallery`;
    return (
      <div className={nodeClasses}>
        <div className="node-left">
          <div className="node-header">
            <h1 className="header-title">{title}</h1>
            {date && time ? (
              <div className="header-datetime">
                <div className="header-date">{date}</div>
                <div className="header-time">{time}</div>
              </div>
            ) : (
              <Button
                className="watch-button"
                onClick={() => {
                  document
                    .querySelector("#details")
                    .scrollIntoView({ block: "start", behavior: "smooth" });
                }}
              >
                {this.props.t("button.watch")}
              </Button>
            )}
          </div>
          <div className="node-content">
            <div
              className="content-description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <div className="content-details">
              <div className="details-summary">
                {additionalDesc ? (
                  <div
                    className="additional-description"
                    dangerouslySetInnerHTML={{
                      __html: additionalDesc,
                    }}
                  />
                ) : null}
                {this.renderPaymentStatus()}
                {this.renderVideoInfo()}
                {this.renderCountdown()}
              </div>
              <div id="details" className="details-buttons">
                {this.renderTeaserButton()}
                {this.renderPaymentButton(this.props.accessButton)}
                {this.renderPaymentButton(this.props.paymentButton)}
                {this.renderWatchButton()}
              </div>
            </div>
          </div>
        </div>
        <div className="node-right media">{this.renderMedia()}</div>
      </div>
    );
  }
}

export default withTranslation()(Node);
