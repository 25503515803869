import React from "react";
import { connect } from "react-redux";
import {
  additionalUrl,
  additionalUrlText,
  multipleLanguages,
  showContact,
  showFaq,
  showHowToBuy,
} from "../../theme-variables";
import { withTranslation } from "react-i18next";
import { getConfig } from "./../../actions/appConfigActions";
import MiniFooter from "./MiniFooter";
import LanguageSelector from "../LanguageSelector";
import "./Footer.scss";

class Footer extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }
  render() {
    const { t } = this.props;
    const {
      institution_page,
      institution_page_title,
      regulations_page,
      policy_page,
    } = this.props.config;
    return (
      <footer className="footer">
        <div className="footer-wrapper">
          <div className="footer-left">
            <a
              href={institution_page || "https://teapp.pl"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {institution_page_title
                ? institution_page_title.replace(/ /g, "\u00a0")
                : t("footer.institution")}
            </a>
            <a
              href={regulations_page || "/regulamin"}
              rel="noopener noreferrer"
            >
              {t("footer.regulations")}
            </a>
            <a href={policy_page || "/polityka"} rel="noopener noreferrer">
              {t("footer.policy")}
            </a>
            {showContact ? (
              <a href={"/kontakt"} rel="noopener noreferrer">
                {t("footer.contact")}
              </a>
            ) : null}
            {showHowToBuy ? (
              <a href={"/n/jakkupicbilet"} rel="noopener noreferrer">
                {t("footer.htb")}
              </a>
            ) : null}
            {additionalUrl && additionalUrlText ? (
              <a href={additionalUrl} target="_blank" rel="noopener noreferrer">
                {additionalUrlText}
              </a>
            ) : null}
            {showFaq ? (
              <a
                href="https://teapp.pl/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.faq")}
              </a>
            ) : null}
            {multipleLanguages ? <LanguageSelector /> : null}
          </div>
          <div className="footer-right">
            <MiniFooter black={true} variant={1} showLanguageSelector={false} />
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(
  withTranslation()(Footer)
);
