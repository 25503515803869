import React from "react";
import Wrapper from "../wrappers/Wrapper";
import FutureEvents from "./events/FutureEvents";
import AvailableVideos from "./videos/AvailableVideos";
import SlickSlider from "./slider/SlickSlider";
import { Skeleton } from "antd";
import {
  reverseVodEventsOrder,
  vodText,
  vodTextEn,
  repertoireText,
  repertoireTextEn,
  giftText,
  giftTextEn,
} from "../../theme-variables";
import vod from "../../apis/vod";
import { withTranslation } from "react-i18next";
import FestivalEvents from "./festival/FestivalEvents";
import GiftBlock from "./gifts/GiftBlock";
import moment from "moment";

class NoAuthDashboard extends React.Component {
  state = {
    videos: [],
    slides: [],
    events: [],
    config: {},
    giftConfig: {},
    groupTicketConfig: {},
  };
  fetchSlides = () => {
    vod
      .get("/external_api/slides")
      .then((res) => {
        this.setState({ slides: res.data.slides });
      })
      .catch((err) => console.error(err));
  };
  fetchVideos = () => {
    vod
      .get("/external_api/videos")
      .then((res) => {
        this.setState({ videos: res.data.videos });
      })
      .catch((err) => console.error(err));
  };
  fetchEvents = () => {
    vod
      .get("/external_api/events")
      .then((res) => {
        this.setState({ events: res.data.events });
      })
      .catch((err) => console.error(err));
  };
  fetchConfig = () => {
    vod
      .get("/external_api/config")
      .then((res) => {
        this.setState({
          config: res.data.config,
          giftConfig: res.data.giftConfig,
          groupTicketConfig: res.data.groupTicketConfig,
        });
      })
      .catch((err) => console.error(err));
  };
  componentDidMount() {
    this.fetchConfig();
    this.fetchEvents();
    this.fetchVideos();
    this.fetchSlides();
  }
  renderVod(videos, vodFilters, show) {
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? vodText || this.props.t("dashboard.vod_title")
              : vodTextEn || this.props.t("dashboard.vod_title")}
          </h2>
          <AvailableVideos allowFilters={vodFilters} videos={videos} />
          <br />
        </>
      );
  }
  renderRepertoire(events, show) {
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? repertoireText || this.props.t("dashboard.repertoire_title")
              : repertoireTextEn || this.props.t("dashboard.repertoire_title")}
          </h2>
          <FutureEvents events={events} />
          <br />
        </>
      );
  }

  renderFestival(events, show) {
    const {
      festival_title,
      festival_title_en,
      festival_short_dates,
      festival_show_finished,
      festival_reverse_finished,
    } = this.state.config;
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? festival_title
              : festival_title_en}
          </h2>
          <FestivalEvents
            shortDates={
              festival_short_dates === 1 || festival_short_dates === true
            }
            showFinished={
              festival_show_finished === 1 || festival_show_finished === true
            }
            reverseFinished={
              festival_reverse_finished === 1 ||
              festival_reverse_finished === true
            }
            events={events}
          />
          <br />
        </>
      );
  }
  renderGiftBlock() {
    const { any_gift_available, gift_expiration } = this.state.giftConfig;
    if (!any_gift_available || moment(gift_expiration).isBefore(moment()))
      return null;

    return (
      <div id="prezent">
        <h2>
          {this.props.i18n.language === "pl"
            ? giftText || this.props.t("dashboard.gift_title")
            : giftTextEn || this.props.t("dashboard.gift_title")}
        </h2>
        <GiftBlock
          config={this.state.giftConfig}
          language={this.props.i18n.language}
        />
      </div>
    );
  }
  render() {
    const { events, videos, config, slides, loadingConfig } = this.state;
    const {
      view_slider,
      view_eventslist,
      view_vod,
      vod_filters,
      view_festival,
    } = config;
    const repertoireEvents = events.filter(
      (event) =>
        event.is_festival === undefined ||
        event.is_festival === null ||
        event.is_festival === false ||
        event.is_festival === 0
    );
    const festivalEvents = events.filter(
      (event) => event.is_festival === true || event.is_festival === 1
    );
    if (!loadingConfig)
      return (
        <Wrapper fullWidth={true}>
          {view_slider ? (
            <SlickSlider slides={{ slides, loading: false }} />
          ) : null}
          <div className="content-wrapper">
            <div className="content">
              {this.renderFestival(festivalEvents, view_festival)}
              {reverseVodEventsOrder ? (
                <>
                  {this.renderVod(videos, vod_filters, view_vod)}
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                </>
              ) : (
                <>
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                  {this.renderVod(videos, vod_filters, view_vod)}
                </>
              )}
              {this.renderGiftBlock()}
            </div>
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

export default withTranslation()(NoAuthDashboard);
